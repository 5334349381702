import React, { useEffect, useMemo, useState } from 'react'
import { useSearchParam } from 'react-use';
import { Alert } from '@chakra-ui/alert';
import { FiAlertTriangle } from 'react-icons/fi'
import { Spinner } from '@chakra-ui/spinner';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Box, Stack } from '@chakra-ui/layout';

import Layout from '../containers/Layout';
import Container from '../components/Container'
import Image from '../components/Image'
import Text from '../components/Text'
import Button from '../components/Button'
import { responsive } from '../components/ThemeProvider/theme';

const Thanks = ({ data: { allWcProducts: { nodes } } }) => {
  const productById = useMemo(() => nodes.reduce((all, product) => {
    all[product.wordpress_id] = product
    return all
  }, {}), [nodes])
  const orderId = useSearchParam('orderId')
  const orderKey = useSearchParam('orderKey')
  const [orderData, setOrderData] = useState()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  useEffect(() => {
    (async () => {
      setLoading(true)
      const { getDatabase, ref, query: dbQuery, orderByChild, equalTo, limitToFirst, onValue } = await import("firebase/database");
      const db = getDatabase();

      try {
        const orderQuery = dbQuery(ref(db, 'orders'), orderByChild('order_key'), equalTo(orderKey), limitToFirst(1))
        onValue(orderQuery, (snapshot) => {
          const data = snapshot.val();
          let order = data[orderId]
          order = {
            ...order,
            product: order?.line_items[0],
          }
          setOrderData(order)
          setLoading(false)
        });
      } catch (e) {
        setError(e.message)
        setLoading(false)
      }
    })()
  }, [orderKey, orderId])
  // console.log(orderData)
  return (
    <Layout title="感謝支持">
      <Container py={responsive('1em', '2em')}>
        <Stack maxWidth={responsive('auto', '460px')} mx="auto" spacing={responsive('1em', '2em')}>
          {loading && <Box textAlign="center"><Spinner /></Box>}
          {error && (
            <Alert status="error">
              <FiAlertTriangle />
              {error}
            </Alert>
          )}
          {orderData && (productById[orderData.product?.product_id]?.images[0]?.localFile ? (
            <GatsbyImage
              image={getImage(productById[orderData.product.product_id]?.images[0]?.localFile)}
              alt={orderData.product.name}
            />
          ) : <Image src={productById[orderData.product?.product_id]?.images[0]?.src} />)}
          <Stack>
            <Text.Title>付款成功</Text.Title>
            <Text.P>感謝你的支持！</Text.P>
          </Stack>
          <Text.S>你的紀念品將在7個工作天內寄出！也請留意你的信箱看看我們給你的感謝卡</Text.S>
          <Button
            fontSize={responsive('1.25em', '1.375em')}
            height="2.5em"
            bg="black"
            rounded="full"
            color="white"
            to="/"
          >回到首頁</Button>
        </Stack>
      </Container>
    </Layout>
  )
}

export default Thanks

export const query = graphql`
query ThanksQuery {
  allWcProducts {
    nodes {
      wordpress_id
      images {
        src
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
            )
          }
        }
      }
    }
  }
}
`
