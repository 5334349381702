import React, { forwardRef } from 'react';
import { Text } from "@chakra-ui/react";
import styled from '@emotion/styled'
import { responsive } from './ThemeProvider/theme';

const ReText = styled(Text)``

ReText.Inline = forwardRef((props, ref) => <ReText as="span" {...props} ref={ref} />);
ReText.Bold = forwardRef((props, ref) => <ReText fontWeight="700" {...props} ref={ref} />);
ReText.Thin = forwardRef((props, ref) => <ReText fontWeight="200" {...props} ref={ref} />);
ReText.S = forwardRef((props, ref) =>
  <ReText
    whiteSpace="pre-wrap"
    fontSize={responsive("0.875em", '1.125em')}
    lineHeight={responsive('1.7', '1.78')}
    fontWeight="500"
    {...props}
    ref={ref}
  />
);
ReText.P = forwardRef((props, ref) =>
  <ReText
    whiteSpace="pre-wrap"
    fontWeight="700"
    fontSize={responsive("1em", '1.25em')}
    lineHeight={responsive(2, 1.75)}
    {...props}
    ref={ref}
  />
);

ReText.R = forwardRef((props, ref) =>
  <ReText.P
    fontSize={responsive("0.875em", '1em')}
    {...props}
    ref={ref}
  />
);

ReText.Title = forwardRef((props, ref) =>
  <ReText
    as="h2"
    fontWeight="900"
    fontSize="1.5em"
    whiteSpace="pre-wrap"
    {...props}
    ref={ref}
  />
);


ReText.SectionTitle = forwardRef((props, ref) =>
  <ReText
    as="h2"
    fontWeight="700"
    fontSize={responsive('1.125em', '2em')}
    mb="0.25em"
    {...props}
    ref={ref}
  />
);

export default ReText
